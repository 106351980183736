import React from 'react';




export default function Setting() {


    return (
        <div>
            <h1>Settings</h1>
        </div>
    );
}

